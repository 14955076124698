import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import SvgLeaf from "../../images/leaf.svg"

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons";
import { ReactComponent as MapIcon }from "feather-icons/dist/icons/map-pin.svg";
import store , { filterGetData } from '../../store'
import {connect, useSelector} from 'react-redux'
// import store from "../../store";


const Container = tw.div`relative w-full  sm:max-w-lg md:max-w-md`;
const Content = tw.div`max-w-2xl lg:w-full mx-auto pt-1 pb-5 lg:py-1 lg:pt-2 px-3`;

const FormContainer = styled.div`
  ${tw`p-4 sm:p-2 md:p-4 bg-gray-100 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative `;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

const cstyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma('#F1484F');
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'gray'
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'black'
          : 'white'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#F1484F' : color.alpha(0.7).css()),
      },
    }
  },
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },

 
};

 const Mapico= styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const handleChange = e =>{
  console.log(e.target.value) 
}


function SearchBox () {
  const options = useSelector((state)=> state.demoFunction.area)
  return (

    <Container>

      <Content>
      <FormContainer>
       
          <div tw="mx-auto ">
            
            <form action="#">
  
                  <InputContainer  >
  <Mapico tw="inline-block sm:hidden"><MapIcon/></Mapico> 
  <Select isMulti isClearable isSearchable options={options} onChange={(value)=> {
    store.dispatch(filterGetData({ "value": value, "cuisines": false}))
    } } id="searcharea-input" tw="w-full inline-block"  styles={cstyles} name="searcharea" placeholder=" Select your regions."  />
  {/* <Select isMulti isClearable isSearchable options={options} onChange={(value)=> addArticle(value)} id="searcharea-input" tw="w-full inline-block"  styles={cstyles} name="searcharea" placeholder="Filter by cuisine."  /> */}
                  </InputContainer>
            </form>
          </div>
         
        </FormContainer>
      </Content>
    </Container>
  );
};

export default SearchBox;