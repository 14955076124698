import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "ofc/misc/Layouts";
import tw from "twin.macro";
import { withRouter } from 'react-router-dom'
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "ofc/headers/light.js"; //dark
import Footer from "ofc/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "ofc/misc/Headings";
import GridForRestaurant2 from '../ofc/cards/GridForRestaurants2'
import { useDispatch } from 'react-redux'

import CousineMenu from "ofc/cards/CuisineMenu2.js";
import SearchBox from "ofc/forms/SearchBox.js";
import SliderCard from "ofc/cards/ListingsSlider.js";
import {  filterGetData }  from '../store'
import store from '../store'
 import DocumentMeta from 'react-document-meta';
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
function Restaurants2(props) {
  useEffect(()=>{    
    window.scrollTo(0, 0)
  }, [])
  // const tabs = useSelector((state)=> state.demoFunction.promoFt);
  // const cards = useSelector((state)=> state.demoFunction.promodata);    

    const meta = {
    title: 'Restaurants Near Me – Find Best Restaurants in India | Best Food Finders',
    description: 'Discover the best restaurants near you at Best Food Finders. Choose from over 1000 different restaurants to enjoy a delicious meal at.',
    canonical: 'https://bestfoodfinders.com/restaurants',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'best food finders, food guide india, restaurants,near me'
        }
    }
};
  return (

    <AnimationRevealPage>
         <DocumentMeta {...meta} />
      <Header searchBoxRequired={false}  />
      <SearchBox />
      <GridForRestaurant2/>            
      <Footer />
    </AnimationRevealPage>
  );
};


export default withRouter(Restaurants2);