import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "ofc/misc/Layouts.js";
import { SectionHeading } from "ofc/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app';
import 'firebase/auth';
import { fetchFilterdPromoData, fetchPromoData } from '../../actions'
import { useDispatch } from 'react-redux'
import { url, prefixImageUrl } from "../../constant";
import { ReactComponent as PriceIcon,  } from "images/price-tag.svg";
import { ReactComponent as AffordableIcon,  } from "images/Affordable.svg";
import { ReactComponent as HighRangeIcon,  } from "images/HighRange.svg";
import { ReactComponent as MidRangeIcon,  } from "images/MidRange.svg";
import store, {filterGetData} from '../../store'

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 `;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0  `;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center border-2  border-dashed border-primary-100 `}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600 truncate `;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg py-3 my-10 sm:py-3`;

const CardPricingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 right-0 mr-4 mb-4 rounded-full px-5 py-2 items-end`;

const GridForRestaurant2 = ({
  history,
  heading = "Restaurants found",
}) => {
  const cards = useSelector((state)=> state.demoFunction.store)
  const disableLoadMore = useSelector((state)=> state.demoFunction.disableLoadMore)
  const loading = useSelector((state)=> state.demoFunction.loading)  
  const totalLength = useSelector((state)=> state.demoFunction.totalRes)

  useEffect(()=>{    
    // store.dispatch(filterGetData("first"))
  },[])

  const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          {loading ? '' : <Header><HighlightedText>{totalLength}</HighlightedText> {heading}</Header>}
        </HeaderRow>

          {loading ? <HighlightedText>Loading...</HighlightedText> : <TabContent            
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
          >
            {cards.map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest" onClick={()=> {console.log(card.routeField); history.push({pathname: "/restaurants/"+card.routeField, state: {data: card}})}}>
                  <CardImageContainer imageSrc={card.featured_image} altKey={card.name + card.shortDesceription}>
                    {card.rating !=0 && <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {parseFloat(card.rating).toFixed(1)}
                      </CardRating>
                    </CardRatingContainer>}

                    {card.priceRange && <CardPricingContainer>
                        <CardRating>
                        {card.priceRange == 'Affordable' && <AffordableIcon />}
                        {card.priceRange == 'Mid range' && <MidRangeIcon />}                      
                        {card.priceRange == 'High end' && <HighRangeIcon />}
                        
                        {card.priceRange == 'Affordable' && "Affordable"}
                        {card.priceRange == 'Mid range' && "Mid-Range"}
                        {card.priceRange == 'High end' && "High-End"}
                        </CardRating>  
                    </CardPricingContainer>}

                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.name}</CardTitle>                  
                    <CardContent>{card.shortDesceription}</CardContent>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>         }
        {disableLoadMore ? "" : <center><PrimaryButton onClick={()=> store.dispatch(filterGetData("loadMore"))}>Load more</PrimaryButton></center>  }           
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default withRouter(GridForRestaurant2);