import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "ofc/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "ofc/headers/dark.js";
import Footer from "ofc/footers/FiveColumnWithInputForm.js";

//import Footer from "ofc/footers/SimpleFiveColumn.js";

import { SectionHeading } from "ofc/misc/Headings";

import { connect } from 'react-redux'

import CousineMenu from "ofc/cards/CuisineMenu2.js";
import SearchBox from "ofc/forms/ListFIlters.js";
import SliderCard from "ofc/cards/ListingsSlider.js";


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
function Listings (props) {
  // console.log(props.counter)
  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])
  return (
    <AnimationRevealPage>
      <Header />
      <Container >
        <Content2Xl>
           <CousineMenu />
          <SliderCard />
        </Content2Xl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const mapStateToProps = (state) => {
  return {
     counter: state.demoFunction.data
  };
};

export default connect(mapStateToProps)(Listings);