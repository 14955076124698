//export const url = "http://127.0.0.1:5000";
 export const url = "https://api.bestfoodfinders.com";
//export const prefixImageUrl = "https://bestfoodfinders.com/images/";

export const prefixImageUrl = "https://bestfoodfinders.com/images/";

export const cuisine = [
{
"label":"Chinese",
"value": "Chinese"
},
{   
"label": "Malay/Indonesian",
"value": "Malay/Indonesian"        
},
{
"label":"Indian",
 "value": "Indian",
},
{
"value": "Western",
"label":"Western",
},
{
"value": "Mediterranean",
"label":"Mediterranean",
},
{
"value":"Thai",
"label": "Thai",
},

{
"label":"Japanese",
"value": "Japanese",
},

{
"value":"Korean",
"label": "Korean",
},

{
"label": "Vietnamese",
"value": "Vietnamese",
},

{
"value":"Mexican",
"label": "Mexican",
}
]