import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "ofc/misc/Layouts";
import tw from "twin.macro";
import { withRouter } from 'react-router-dom'
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "ofc/headers/light.js";
import Footer from "ofc/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "ofc/misc/Headings";
import TabGrid from '../ofc/cards/TabGridForOtherPromo'
import { useSelector } from 'react-redux'

import CousineMenu from "ofc/cards/CuisineMenu2.js";
import SearchBox from "ofc/forms/ListFIlters.js";
import SliderCard from "ofc/cards/ListingsSlider.js";
import {url} from '../constant'


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
function OtherPromotions({history, location}) {  
  const [cards, setCards] = React.useState([]);

  useEffect(()=>{
    window.scrollTo(0, 0)
    if(location.state != undefined){
        console.log(location.state.data)
        fetch(url+'/getSubCoponOnly',{
            method: "POST",
            body: JSON.stringify({"id": location.state.data}) 
        })
        .then(res => res.json())
        .then((data)=>{          
            if(data['status']=="okay"){
                setCards(data['response'])
            }
        })
    }else{
        history.push('/')
    }

  }, [])
  return (
    <AnimationRevealPage>
      <Header searchBoxRequired={false} promo={false} />
        <TabGrid cards={cards} />
      <Footer />
    </AnimationRevealPage>
  );
};


export default withRouter(OtherPromotions);