import { combineReducers } from 'redux';
import store from '../store';

const initialState = {
      data :['ejjkjk'],
      promodata: [],
      promodataCopy: [],
      cards : [],
      store: [],
      storeCopy: [],
      allowLogin: false,
      isSignUp: false,
      userSignedIn: false,
      disableLoadMore: false,
      promoFt: {},
      area: [],
      loading: false,
      totalRes: 0,
      disablePromoLoad: false,
      regionSelected: [],
      cuisineSelected: [],
      cities: [],
      ft: [],
      specialFoods: [],
      
      citySelected: [],
      ftSelected: [],
      specialFoodsSelected: []
}

function demoFunction (state = initialState, action){      

      
      if(action.type =='get_data'){
            console.log(action.prop)
            return state
      }
      if(action.type == 'user_signed_in'){
            state.userSignedIn = action.payload
            return state;
      }
      if(action.type == 'sign_up'){            
            state.isSignUp = action.payload
            return state;
      }
      if(action.type == 'fetch_data'){
            if(!action.fromCat){
                  state.disableLoadMore = action.disableButton;
                  state.totalRes = action.resLength
                  return {...state,  store: state.store.concat(action.payload)}
            }else if(action.fromCat){                  
                  state.disableLoadMore = action.disableButton;
                  state.totalRes = action.resLength
                  state.store = action.payload
                  return state
            }
      }
      if(action.type == 'promo_data'){                                 
            state.disablePromoLoad = action.disableButton
            state.promodataCopy = state.promodataCopy.concat(action.payload)
            state.promodata = state.promodataCopy
            return state
      }
      if(action.type == 'promo_ft'){                     
            state.promoFt = action.payload                  
            return state;
      }
      if(action.type == 'cuisine_data'){                     
            state.cards = action.payload                  
            return state;
      }
      if(action.type == 'area_data'){                     
            state.area = action.payload                  
            return state;
      }
      if(action.type == 'loading'){                     
            state.loading = action.payload                  
            return state;
      } 
      if(action.type == 'promo_filter_data'){   
            if(action.payload == 'all'){
                  state.promodata = state.promodataCopy;
            }else{
                  state.promodata = state.promodataCopy.filter(data=> data.type == action.payload)
            }
            return state;
      }
      if(action.type == 'cuisineSelected'){
            state.cuisineSelected = action.payload                  
            return state;
      }
      if(action.type == 'regionSelected'){
            state.regionSelected = action.payload                  
            return state;
      }
      if(action.type == 'cityData'){
            state.cities = action.payload                  
            return state;
      }
      if(action.type == 'specialFoodsData'){
            state.specialFoods = action.payload                  
            return state;
      }
      if(action.type == 'feature&tagsData'){
            state.ft = action.payload                  
            return state;
      }
      if(action.type == 'citySelected'){
            state.citySelected = action.payload                  
            return state;
      }
      if(action.type == 'ftSelected'){
            state.ftSelected = action.payload                  
            return state;
      }
      if(action.type == 'sfSelected'){
            state.specialFoodsSelected = action.payload                  
            return state;
      }
      else{
          return state;
      }
}

const reducer =  combineReducers({
    demoFunction: demoFunction
});

export default reducer;