import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "ofc/misc/Layouts.js";
import { SectionHeading } from "ofc/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app';
import 'firebase/auth';
import { fetchFilterdPromoData } from '../../actions'
import { useDispatch } from 'react-redux'
import { url, prefixImageUrl } from "../../constant";

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 `;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0  `;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center border-2  border-dashed border-primary-100 `}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-primary-600`;
const CardContentTwo = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const TabGridForPromo = ({
  heading = "Other promotions",  
  cards,
  history,
}) => {
    const getTheCoupon = (code) =>{    
        if(firebase.auth().currentUser){      
          if(code.used.filter(item=> item.email == firebase.auth().currentUser.email).length == 0){        
            code.userEmail = firebase.auth().currentUser.email            
            code.userName = firebase.auth().currentUser.displayName   
            code.uid = firebase.auth().currentUser.uid
            code.userStatus = 'Received'
            
            fetch(url+'/getTheCoupon',{
                method: "POST",
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify(code)
            })
            .then((res)=> res.json())
            .then((data)=>{
                if(data['status']=="okay"){
                  history.push('/profile')
                }
            })
          }
          else{
            alert('You received this coupon already')
          }
        }
        else{
          history.push('/signup')
        }
    }

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>
        <TabContent
            // key={}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
        >
            {cards.map((card, index) => (
               new Date().toISOString().split('T')[0] > card['valid_upto'] ? ' ' : <CardContainer key={index}>
              <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                <CardImageContainer imageSrc={String(card.promoImage).length > 50 ? prefixImageUrl+card.promoImage.split(':')[2].split("/")[2]+'.jpg' : prefixImageUrl+card.promoImage }>                  
                  { new Date().toISOString().split('T')[0] < card['availFrom'] ? '' : card['total_no_of_coupons'] == 5 ? '' : <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton onClick={()=> getTheCoupon(card)}>Get It!</CardButton>
                    </CardHoverOverlay>}
                </CardImageContainer>
                <CardText>
                {new Date().toISOString().split('T')[0] < card['availFrom'] ? <CardContentTwo>Avail Soon</CardContentTwo> : ''}
                    {card['total_no_of_coupons'] < 5 ? <CardContentTwo>Few only available</CardContentTwo> : ''}
                  <CardTitle>{card.title}</CardTitle>
                  <CardContent>Valid upto {" "}{card.valid_upto}</CardContent>                    
                  <CardPrice>{card.price}</CardPrice>
                </CardText>
              </Card>
            </CardContainer>
            ))}
          </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default withRouter(TabGridForPromo);