import React, { useState, useEffect } from 'react'
import Header from "../ofc/headers/light";
import AnimationRevealPage from "../helpers/AnimationRevealPage"
import ProfileDetails from '../ofc/cards/ProfileDetails'
import firebase from 'firebase/app';
import 'firebase/auth';
import TabGrid from '../ofc/cards/TabCardGridForProfile'
import { url } from "../constant";
import { withRouter } from 'react-router-dom'

const Profile =({history})=>{
    const isCancelled = React.useRef(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [promoCard, setPromoCard] = useState([]);

    useEffect(()=>{
        window.scrollTo(0, 0)
        setTimeout(() => getUid(), 2000);

        return () => {
            isCancelled.current = true;
        };
    },[])
    
    const getUid = () =>{
        if(firebase.auth().currentUser.uid){
            fetch(url+'/getUserProfile',{
                method: 'POST',
                body: JSON.stringify({ 'uid': firebase.auth().currentUser.uid })
            })
            .then(res => res.json())
            .then((data)=>{          
                if(!isCancelled.current){
                    setName(data['response']['userName'])
                    setEmail(data['response']['email'])
                    setPromoCard(data['response']['couponsReceived'])
                }
            })
        } else {
            history.push('/')
        }
    }
    return(
        <AnimationRevealPage>
            <Header/>
            <ProfileDetails name={name} email={email}/>
            {promoCard.length > 0 ? <TabGrid cards={promoCard} email={email}/> : ''}
        </AnimationRevealPage>
    )
}

export default withRouter(Profile);