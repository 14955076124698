import React, {useEffect, useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "ofc/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/sweetpoteto.jpg";
import logo from "images/logo.svg";
import { useSelector } from "react-redux";

import firebase from 'firebase/app'
import 'firebase/auth';
import { url } from "../constant"

import googleIconImageSrc from "images/google-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { withRouter } from "react-router-dom";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;

const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-16 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-xl xl:text-xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}"); background-size:cover;`}
  ${tw`sm:rounded-r-lg flex-1  text-center hidden lg:flex justify-center `}
`;

const ForgotPwd = ({  
    history, 
    logoLinkUrl = "/",    
    signupUrl = "/signup"
}) => {  
  const [ email, setEmail ] = useState('');
  const [ pwd, setPwd ] = useState('');  

  const allowLogin = useSelector((state)=> state.demoFunction.allowLogin);

  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])
  
  const handleForgotPwdFormSubmit = async (e) =>{
    e.preventDefault()
    await firebase.auth().sendPasswordResetEmail(email)
    .then(data =>{
        console.log(data)
        alert("Password reset link was sent to your email")         
        window.history.back();        
    })
    .catch(error =>{
        alert(error['message'])        
    })
  }

  const handleTextChange = (e) =>{
    if(e.target.name == 'email'){
      setEmail(e.target.value)
    }
  }

  return(
    <AnimationRevealPage>
      <Container style={{ backgroundImage: `url(require("images/bg.png"))` }}>
        <Content>
          <MainContainer>
            <LogoLink href="/">
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>Enter your e-mail</Heading>
              <FormContainer> 
                <Form onSubmit={handleForgotPwdFormSubmit}>
                  <Input type="email" placeholder="Email" name='email' onChange={handleTextChange}  />                  
                  <SubmitButton type="submit">
                    <span className="text">Submit</span>
                  </SubmitButton>
                </Form>

                <p tw="mt-8 text-sm text-gray-600 text-center" style={{marginTop: 15}}>
                  Already have an account?{" "}
                  <a href="/signin" tw="border-b border-gray-500 border-dotted" style={{color: 'blue'}}>
                    <u>Sign In</u>
                  </a>
                </p>
                <p tw="mt-8 text-sm text-gray-600 text-center" style={{marginTop: 20}}>
                  Dont have an account?{" "}
                  <a href={signupUrl} tw="border-b border-gray-500 border-dotted" style={{color: 'blue'}}>
                    <u>Sign Up</u>
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustration} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  )
};

export default withRouter(ForgotPwd);