import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { prefixImageUrl } from '../../constant'

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;

const CardSlider = styled(Slider)`
  ${tw`mt-1`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-6/12   justify-center mb-1`}
  }
`;
const Card = tw.div`h-auto flex! flex-col sm:border max-w-2xl  relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-64 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-l`
]);

export default ({images, altKey}) => {
  const [sliderRef, setSliderRef] = useState(null);
  useEffect(()=>{
    
    console.log(images)
  }, [])
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
      'infinite': true,
  'autoplay': true,
  'autoplaySpeed': 2000,
  
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "/images/gingerthai.jpg",
      
    },
    {
      imageSrc: "/images/mumbaimagic.jpg",
     
    },
    {
      imageSrc: "/images/flamecafe.jpg",
      
    },
     {
      imageSrc: "/images/shivam.jpg",
     
    },
  ]

  return (
    <Container>
      <Content>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {images.map((card, index) => (
            <Card key={index}>
              <CardImage altKey={altKey} imageSrc={String(card).length == 32 ? prefixImageUrl+card+'.jpg' : String(card).length == 36 ? prefixImageUrl+card : card} />
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
