import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import otherPromotions from "./pages/otherPromotions";

import { Redirect } from 'react-router'

import MainLandingPage from "MainLandingPage.js";
import RestaurantDisplay from "./pages/Restaurant"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {  filterGetData, fetchPromoData, checkUserlogIn, getArea, fetchPromoFieldTypes, getCuisine, getCity, getFeatureAndTags, getSpecialFoods }  from './store'
import store from './store'

import firebase from 'firebase/app'
import 'firebase/auth';

import withFirebaseAuth from 'react-with-firebase-auth'

import Listings  from "ofc/list/List.js";
import ContactUs from "./pages/ContactUs"
import Blog from "./pages/BlogIndex"
import AboutUsPage from "./pages/AboutUs"
import Profile from "./pages/Profile"
import SignIn from "./pages/Login"
import SignUp from "./pages/Signup"
import Promotions from './pages/Promotions'
import Restaurant2 from './pages/Restaurant2'
import Cs from './pages/Cs'
import ForgotPwd from './pages/ForgotPwd'

const firebaseAppAuth = firebase.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

class App extends React.Component{
  componentDidMount(){      
    store.dispatch(filterGetData("first"))
    store.dispatch(fetchPromoData)
    store.dispatch(checkUserlogIn)
    store.dispatch(fetchPromoFieldTypes)
    store.dispatch(getCuisine)
    store.dispatch(getArea)
    store.dispatch(getCity)
    store.dispatch(getFeatureAndTags)
    store.dispatch(getSpecialFoods)
  }

  render(){
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={MainLandingPage}/>
        <Route path='/restaurants/:id' exact component={RestaurantDisplay} />
        <Route path="/restaurants2" component={Listings}/>
        <Route path="/promotions" component={Promotions}/>       
        <Route path="/profile" component={Profile}/>
        <Route path="/signin" component={SignIn}/>
        <Route path="/signup" component={SignUp}/>
        <Route path="/aboutus" component={AboutUsPage}/>
    
        <Route path="/contactus" component={ContactUs}/>
        <Route path="/restaurants" component={Restaurant2}/>
        <Route path="/comingsoon" component={Cs}/>
        <Route path="/otherPromotions" component={otherPromotions}/>
        <Route path="/forgotPassword" component={ForgotPwd} />
      
<Route path='/blog' component={() => { 
   // window.location.href = 'https://blog.bestfoodfinders.com/'; 

 window.location.href = 'https://bestfoodfinders.com/blog/'; 

      return null;
}}
/> 

      </Switch>
    </Router>
  )
  }
}

export default withFirebaseAuth({providers, firebaseAppAuth})(App);