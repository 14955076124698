import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "ofc/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { useSelector } from 'react-redux'
import { withRouter } from "react-router-dom";
import { url, prefixImageUrl } from "../../constant";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-center bg-cover rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 overflow-hidden `;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const ThreeColSlider =  ({ history }) => {  
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
  'infinite': true,
  'autoplay': true,
  'autoplaySpeed': 2000,

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  // const cards = useSelector((state)=> state.demoFunction.storeCopy)
  const [cards, setCards] = React.useState([])

  React.useEffect(()=>{
    fetch(url+"/getFeaturedRestaurants",{
      method: "POST"
    })
    .then(res => res.json())
    .then((data)=>{
      if(data['status']=='okay'){
        data['response'].map((card)=>{                
          if(String(card.featured_image).length === 32) {                    
              card.featured_image = prefixImageUrl+card.featured_image+'.jpg';
          } else if (String(card.featured_image).length === 36) {
              card.featured_image =  prefixImageUrl+card.featured_image
          }
        })
        setCards(data['response'])
      }
    })
  }, [])
  
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading><HighlightedText>Featured</HighlightedText> Restaurants</Heading>
          <Controls>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (            
            card.featured ? <Card key={index} onClick={()=>history.push({pathname: "/restaurants/"+card.routeField, state: {data: card}})} >
              <CardImage imageSrc={card.featured_image} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.name}</Title>
                  {card.rating ? <RatingsInfo>
                    <StarIcon />
                    <Rating>{parseFloat(card.rating).toFixed(1)}</Rating>
                  </RatingsInfo>: ''}
                </TitleReviewContainer>
             <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.area}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.shortDesceription}</Description> 
              </TextInfo>
            </Card> : ''
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};

export default withRouter(ThreeColSlider)