import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {connect} from 'react-redux'
// import { signIn } from '../../actions'

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import SearchBox from "ofc/forms/ListFIlters.js";
 
import firebase from 'firebase/app';
import 'firebase/auth';
import { useSelector } from 'react-redux'
import { withRouter } from "react-router-dom";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-2xl mx-auto
 
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-40 mr-6`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const Dark= ({ searchBoxRequired=true ,history ,roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */ 
  const userSignedIn = useSelector((state)=> state.demoFunction.userSignedIn);
  
  const signOut = () =>{
    firebase.auth().signOut().then(()=>{
      history.push("/")
      window.location.reload()
    })      
  }
  const defaultLinks = [
    <NavLinks key={1}>
      {/* <NavLink onClick={()=> history.push('/aboutus')} >About</NavLink>
      <NavLink onClick={()=> history.push('/blog')}>Blog</NavLink>      
      <NavLink onClick={()=> history.push('/contactus')} >Contact Us</NavLink>   */}
      <NavLink onClick={()=> history.push('/promotions')} >Promotions</NavLink>
      <NavLink onClick={()=> history.push('/restaurants')} >Explore restaurants</NavLink>
      <NavLink onClick={()=> history.push('/blog')} >Blog</NavLink>
      {userSignedIn ? history.location.pathname == '/profile' ?
        "" : <NavLink onClick={()=> {
          history.push('/profile');
          console.log("Profile")
          }}
          tw="lg:ml-12!">
          My account
        </NavLink> 
        :
        <NavLink onClick={()=> history.push('/signin')} tw="lg:ml-12!">
          Login
        </NavLink>   
      }
      {userSignedIn ? <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} onClick={signOut} >Sign out</PrimaryLink> : <PrimaryLink onClick={()=> history.push('/signup')} css={roundedHeaderButton && tw`rounded-full`} >          
        <span className="text" > Sign Up</span>
      </PrimaryLink>}
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink >
      <img src={logo} alt="logo" onClick={()=> history.push('/')}  />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-dark"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {defaultLogoLink}        
        {/*  {searchBoxRequired ?<SearchBox  /> : ""} */}
 {links}
       

      </DesktopNavLinks>


      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer} tw="flex-wrap">
      
        {logoLink}
        
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
     {/*  <SearchBox tw="sm:w-screen w-full block" /> */}
       
      </MobileNavLinksContainer>


    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};


export default withRouter(Dark);