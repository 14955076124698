import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import SvgLeaf from "../../images/leaf.svg"

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';
import { withRouter } from "react-router-dom";
import store , { filterGetData } from '../../store'
import {connect, useSelector} from 'react-redux'
import { cuisine } from '../../constant'

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pt-1 pb-5 lg:py-10 lg:pt-2`;

const FormContainer = styled.div`
  ${tw`p-5 sm:p-6 md:p-8 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-6/12 flex flex-col px-2`;
const InputContainer = tw.div`relative py-2 mt-2`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`


const cstyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma('#F1484F');
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'gray'
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'black'
          : 'white'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#F1484F' : color.alpha(0.7).css()),
      },
    }
  },
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};



const SearchBox2 = ({history}) => {
   console.log("loading search box")
  const options = useSelector((state)=> state.demoFunction.area)
  const cuisine = useSelector((state)=> state.demoFunction.cards)
  const cities = useSelector((state)=> state.demoFunction.cities)
  const ft = useSelector((state)=> state.demoFunction.ft)
  const specialFoods = useSelector((state)=> state.demoFunction.specialFoods)

  const cuisineSelected = useSelector((state)=> state.demoFunction.cuisineSelected)
  const regionSelected = useSelector((state)=> state.demoFunction.regionSelected)
  const citySelected = useSelector((state)=> state.demoFunction.citySelected)
  const ftSelected = useSelector((state)=> state.demoFunction.ftSelected)
  const specialFoodsSelected = useSelector((state)=> state.demoFunction.specialFoodsSelected)

  return (

    <Container>
 
      <Content>
     
      <img src={SvgLeaf}  tw="object-none object-right-bottom px-20 w-full h-10" />
     
        <FormContainer>
       
          <div tw="mx-auto ">
            <h2>Explore Restaurants</h2>
            <form >
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Select 
                      isMulti 
                      isClearable 
                      isSearchable
                      cuisineSelected
                      options={cities} 
                      value={citySelected}
                      onChange={(value)=> { 
                        store.dispatch(filterGetData({ "key": value, "city": true})) 
                        console.log("Selected")
                      }} 
                      id="selectCity"
                      styles={cstyles} 
                      name="selectCity" 
                      placeholder="City"
                    /> 
                  </InputContainer>
                </Column>
                <Column> 
                  <InputContainer>
                    <Select 
                      isMulti 
                      isClearable 
                      isSearchable 
                      options={options}
                      value={regionSelected}
                      onChange={(value)=> {                        
                        store.dispatch(filterGetData({ "value": value, "cuisines": false})) 
                      }}
                      id="selectArea"
                      styles={cstyles} 
                      name="selectArea"
                      placeholder="Area"  
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Select 
                      isMulti 
                      isClearable 
                      isSearchable
                      cuisineSelected
                      options={cuisine} 
                      value={cuisineSelected}
                      onChange={(value)=> { 
                        store.dispatch(filterGetData({ "key": value, "cuisines": true}))
                      }} 
                      id="selectCuisines"   
                      styles={cstyles} 
                      name="selectCuisines" 
                      placeholder="Cuisines"  
                    /> 
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Select 
                      isMulti 
                      isClearable 
                      isSearchable
                      cuisineSelected
                      options={specialFoods} 
                      value={specialFoodsSelected}
                      onChange={(value)=> { 
                        store.dispatch(filterGetData({ "key": value, "sf": true}))
                      }} 
                      id="selectSpecialFoods"   
                      styles={cstyles} 
                      name="selectSpecialFoods" 
                      placeholder="Special foods"  
                    /> 
                  </InputContainer>
                </Column>
                <Column> 
                  <InputContainer>
                    <Select 
                      isMulti 
                      isClearable 
                      isSearchable 
                      options={ft}
                      value={ftSelected}
                      onChange={(value)=> {                        
                        store.dispatch(filterGetData({ "key": value, "ft": true}))
                      }}
                      id="selectFT"
                      styles={cstyles} 
                      name="selectFT" 
                      placeholder="Features & tags"  
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};

export default withRouter(SearchBox2);