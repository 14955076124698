import React, { useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "ofc/hero/TwoColumnWithVideo.js"; // ofc/hero/TwoColumnWithVideo.js

//import Hero from "ofc/hero/TwoColumnWithPrimaryBackground.js";

//import Hero from "ofc/hero/BackgroundAsImage.js";

import Features from "ofc/features/ThreeColSimple.js";
import MainFeature from "ofc/features/TwoColWithButton.js";
import MainFeature2 from "ofc/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "ofc/cards/TabCardGrid.js";
import Testimonial from "ofc/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "ofc/cta/DownloadApp.js";
import Footer from "ofc/footers/FiveColumnWithInputForm.js";

//import Footer from "ofc/footers/SimpleFiveColumn.js";

import Blog from "ofc/blogs/PopularAndRecentBlogPosts.js";

import GetStartedCTA from "ofc/cta/GetStarted.js";

import CousineMenu from "ofc/cards/CousineMenu.js";
import SearchBox from "ofc/forms/SearchBox.js";
import {useSelector} from 'react-redux'

import SliderCard from "ofc/cards/ThreeColSlider.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

import DocumentMeta from 'react-document-meta';

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const cards = useSelector((state)=> state.demoFunction.promodata);    
  const tabs = useSelector((state)=> state.demoFunction.promoFt);

  const meta = {
    title: 'Best Food Finders | Food Guide India - Restaurants Near You',
    description: 'Best Food Finders is the #1 online food guide destination in India for amazing restaurants, awesome cuisine options & exciting offers. Visit us today!',
    canonical: 'https://www.bestfoodfinders.com/',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'best food finders, food guide india, restaurants,near me'
        }
    }
  };


  useEffect(()=>{ 
    window.scrollTo(0, 0)    
  }, [])
  return (
    
    <AnimationRevealPage>
     <DocumentMeta {...meta} />
      <Hero
        heading={<>Delicious & Affordable <HighlightedText>Meals Near You.</HighlightedText></>}
        description="Best Food Finders - The largest food directory with video features. Discover Great Places To Eat In India. "
        imageSrc="/images/dosa.jpg"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Explore Now"
        watchVideoButtonText="BFF Top Today"
      />

      <GetStartedCTA


  text = "We are rolling out promotions soon. Sign up to know on deals and discounts."
  primaryLinkText = "Get Started"
  primaryLinkUrl = "/signup"
  secondaryLinkText = "Contact Us"
  secondaryLinkUrl = "/contactus"


       />
        <SliderCard />           
    {  /*  <TabGrid
          trim={true}
          heading={
            <>
              Checkout <HighlightedText>Hot</HighlightedText> Promotions
            </>
          }
          tabs={tabs}
          cards={cards}
          routeToPromo={true}
        />*/}
      <Features
        heading={
          <>
            Why <HighlightedText>BFF</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "1000+ Restaurants",
            description: "Explore Great Places To Eat.",
            url: "/aboutus"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Awesome Choices",
            description: "Variety of Cuisines to View.",
            url: "/restaurants"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Happy Deals",
            description: "Hot and Exclusive promos to claim.",
            url: "/promotions"
          }
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
