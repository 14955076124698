const firebaseConfig = {

     apiKey: "AIzaSyBoNdyaWvzrMvPQx4wMzHdkCP1sDR4P_q8",
  authDomain: "bff-website-326612.firebaseapp.com",
  projectId: "bff-website-326612",
  storageBucket: "bff-website-326612.appspot.com",
  messagingSenderId: "426771889823",
  appId: "1:426771889823:web:cefe95aafb988482f9acaa",
  measurementId: "G-Y8EHRC8QGY"

};

export default firebaseConfig;