import firebase from 'firebase/app';
import 'firebase/auth';
 
function getData(b){
    return {
        type: 'get_data',
        prop: b
    }
}

function searchFilter(b){
    return {
        type: 'search_filter',
        prop: b ? b : []
    }
}

function fetchFilterdPromoData(payload){
    return {
        type: 'promo_filter_data',
        payload: payload
    }
} 

export { searchFilter, getData, fetchFilterdPromoData }