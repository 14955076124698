import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "ofc/misc/Layouts.js";
import { SectionHeading } from "ofc/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "ofc/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app';
import 'firebase/auth';
import { fetchFilterdPromoData } from '../../actions'
import store, { fetchPromoData, fetchPromoFieldTypes } from "../../store"
import { useDispatch } from 'react-redux'
import { prefixImageUrl, url } from "../../constant";
import { useSelector } from 'react-redux'

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 `;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0  `;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center border-2  border-dashed border-primary-100 `}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardContentBlack = tw.p`mt-1 text-sm font-medium`;
const CardContentTwo = tw.p`mt-1 text-sm font-medium text-red-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg py-3 my-10 sm:py-3`;

const TabCardGrid = ({
  heading = "Checkout the Menu",
  tabs,
  history,
  cards,
  key,
  trim = false,
  routeToPromo = false
}) => {
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState("");      
  
  const dispatch = useDispatch();     
  const disableLoadMore = useSelector((state)=> state.demoFunction.disablePromoLoad)

  useEffect(()=>{
    setActiveTab("All");
    dispatch(fetchFilterdPromoData(tabs['All']))
    dispatch(fetchPromoFieldTypes)
  }, [])

  const getTheCoupon = (code) => {
    if(firebase.auth().currentUser){      
      if(code.used.filter(item=> item.email == firebase.auth().currentUser.email).length == 0){        
        code.userEmail = firebase.auth().currentUser.email            
        code.userName = firebase.auth().currentUser.displayName   
        code.uid = firebase.auth().currentUser.uid
        code.userStatus = 'Received'
        
        fetch(url+'/getTheCoupon',{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(code)
        })
        .then((res)=> res.json())
        .then((data)=>{
            if(data['status']=="okay"){
              alert("Successfully received")
              history.push('/profile')
              window.location.reload()
            } else if(data['status'] == "Coupons are sold out"){
              alert(data['status'])
              window.location.reload()
            }
        })
      }
      else{
        alert('You received this coupon already')
      }
    }
    else{
      history.push('/signup')
    }
  }
  
  const handleTabChange = (tabName)=>{    
    setActiveTab(tabName)
    dispatch(fetchFilterdPromoData(tabs[tabName]))    
  }

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={()=>handleTabChange(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>
        { cards.length == 0 ? <CardText><CardTitle>There is no promotions</CardTitle></CardText> : 
        tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {cards.map((card, index) => (
              trim ? index < 4 ? new Date().toISOString().split('T')[0] > card['valid_upto'] ? ' ' :  <CardContainer key={index}>
               <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.promoImage.length > 50 ? prefixImageUrl+card.promoImage.split(':')[2].split("/")[2]+'.jpg' : prefixImageUrl+card.promoImage }>                    
                    {new Date().toISOString().split('T')[0] < card['availFrom'] ? '' : <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton onClick={()=> routeToPromo ? history.push('/promotions') : getTheCoupon(card)}>Get It!</CardButton>
                    </CardHoverOverlay>}
                  </CardImageContainer>                  

                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContentBlack>{card.res_name}</CardContentBlack>
                    { card['availFrom'] > new Date().toISOString().split('T')[0] ? <CardContentTwo>Avail Soon</CardContentTwo> : parseInt(card['total_no_of_coupons']) < 5 ? <CardContentTwo>Few only available</CardContentTwo> : ''}                    
                    <CardContent>Avail from {" "}{card.availFrom}</CardContent>                    
                    <CardContent>Valid upto {" "}{card.valid_upto}</CardContent>                    
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer> : '' :
              new Date().toISOString().split('T')[0] > card['valid_upto'] ? ' ' :  <CardContainer key={index}>
              <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                 <CardImageContainer imageSrc={card.promoImage.length > 50 ? prefixImageUrl+card.promoImage.split(':')[2].split("/")[2]+'.jpg' : prefixImageUrl+card.promoImage }>
                   {new Date().toISOString().split('T')[0] < card['availFrom'] ? '' : <CardHoverOverlay
                     variants={{
                       hover: {
                         opacity: 1,
                         height: "auto"
                       },
                       rest: {
                         opacity: 0,
                         height: 0
                       }
                     }}
                     transition={{ duration: 0.3 }}
                   >
                     <CardButton onClick={()=> routeToPromo ? history.push('/promotions') : getTheCoupon(card)}>Get It!</CardButton>
                   </CardHoverOverlay>}
                 </CardImageContainer>

                 <CardText>
                   <CardTitle>{card.title}</CardTitle>
                   <CardContentBlack>{card.res_name}</CardContentBlack>
                   { card['availFrom'] > new Date().toISOString().split('T')[0] ? <CardContentTwo>Avail Soon</CardContentTwo> : parseInt(card['total_no_of_coupons']) < 5 ? <CardContentTwo>Few only available</CardContentTwo> : ''}
                   <CardContent>Avail from {" "}{card.availFrom}</CardContent>                    
                   <CardContent>Valid upto {" "}{card.valid_upto}</CardContent>                    
                   <CardPrice>{card.price}</CardPrice>
                 </CardText>
              </Card>
             </CardContainer>
            ))}
            
          </TabContent>
        ))}
        {trim ? "" : disableLoadMore ? "" : <center><PrimaryButton onClick={()=> store.dispatch(fetchPromoData)}>Load more</PrimaryButton></center>  }
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default withRouter(TabCardGrid);