import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { prefixImageUrl } from '../../constant'

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;

const CardSlider = styled(Slider)`
  ${tw`mt-1`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-6/12   justify-center mb-1`}
  }
`;
const Card = tw.div`h-auto flex! flex-col sm:border border-transparent rounded-3xl max-w-xl max-h-screen   relative focus:outline-none `;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-96 sm:h-96  bg-cover bg-center rounded rounded-3xl`
]);

export default ({images, altKey}) => {
  const [sliderRef, setSliderRef] = useState(null);
  useEffect(()=>{
    
    console.log(images)
  }, [])
  const sliderSettings = {
    arrows: false,
    slidesToShow: 1,
      'infinite': true,
  'autoplay': true,
  'autoplaySpeed': 2800,
  
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "/images/banner (3).jpg",
      
    },
    {
      imageSrc: "/images/banner (2).jpg",
     
    },
    {
      imageSrc: "/images/banner (1).jpg",
      
    },
     {
      imageSrc: "/images/banner (4).jpg",
     
    },
    {
      imageSrc: "/images/banner (5).jpg",
     
    },
    {
      imageSrc: "/images/banner (6).jpg",
     
    },
  ]

  return (
    <Container>
      <Content>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>

              <CardImage altKey={card} imageSrc={card.imageSrc} />
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
